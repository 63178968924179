/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./src/styles/bootstrap.min.css")


require("./src/styles/navigation.css")
require("./src/styles/animate.css")
require("./src/styles/main.scss")
require("./src/styles/style.css")
require("./src/styles/owl.carousel.min.css")
require("./src/styles/flaticon.min.css")
