// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-mainservices-js": () => import("/var/www/html/xigakis/gatsby/src/templates/mainservices.js" /* webpackChunkName: "component---src-templates-mainservices-js" */),
  "component---src-templates-mainprojects-js": () => import("/var/www/html/xigakis/gatsby/src/templates/mainprojects.js" /* webpackChunkName: "component---src-templates-mainprojects-js" */),
  "component---src-templates-mainabout-js": () => import("/var/www/html/xigakis/gatsby/src/templates/mainabout.js" /* webpackChunkName: "component---src-templates-mainabout-js" */),
  "component---src-templates-maincontact-js": () => import("/var/www/html/xigakis/gatsby/src/templates/maincontact.js" /* webpackChunkName: "component---src-templates-maincontact-js" */),
  "component---src-pages-el-404-js": () => import("/var/www/html/xigakis/gatsby/src/pages/el/404.js" /* webpackChunkName: "component---src-pages-el-404-js" */),
  "component---src-pages-el-index-js": () => import("/var/www/html/xigakis/gatsby/src/pages/el/index.js" /* webpackChunkName: "component---src-pages-el-index-js" */),
  "component---src-pages-en-404-js": () => import("/var/www/html/xigakis/gatsby/src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-index-js": () => import("/var/www/html/xigakis/gatsby/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("/var/www/html/xigakis/gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

